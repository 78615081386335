import request from "@/utils/axios";
// 公用API
// 统计核算列表
export function getFinanceList(data) {
  return request({
    url: "/api/finance/financeList",
    method: "post",
    data,
  });
}

// 统计核算审核
export function financialAudit(data) {
  return request({
    url: "/api/finance/financialAudit",
    method: "post",
    data,
  });
}

// 统计核算确认付款
export function confirmPayment(data) {
  return request({
    url: "/api/finance/confirmPayment",
    method: "post",
    data,
  });
}

//海师统计列表
export function getHaishiList(params) {
  return request({
    url: "/api/finance/haishi-statistics",
    method: "get",
    params,
  });
}

// 海师统计列表详情
export function haishiListDetail(params) {
  return request({
    url: "/api/finance/haishi-statistics-details",
    method: "get",
    params,
  });
}

// 客户统计列表详情
export function getClientList(params) {
  return request({
    url: "/api/finance/client-list",
    method: "get",
    params,
  });
}
//客户详情详情
export function getClientDetail(params) {
  return request({
    url: "/api/finance/client-list-details",
    method: "get",
    params,
  });
}
