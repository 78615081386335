<template>
  <div id="NavLeft">
    <div class="sidebar">
      <!-- 账户设置 -->
      <div class="sidebar_account" v-if="user_rule == 2 || user_rule == 3">
        <div class="accounts">账户设置</div>
        <div
          class="box_top"
          :class="xuanzhong === 1 ? 'xuanzhong' : ''"
          v-if="user_rule == 2 || user_rule == 3"
        >
          <router-link to="/personal">个人信息</router-link>
        </div>
        <div
          class="touxiang box_top"
          :class="xuanzhong === 2 ? 'xuanzhong' : ''"
          v-if="user_rule == 2 || user_rule == 3"
        >
          <router-link to="/sethead">修改头像</router-link>
        </div>
      </div>
      <i></i>
      <!-- 订单中心 -->
      <div class="sidebar_order" v-if="user_rule !== 7 || user_rule == 1">
        <div class="order sets">订单中心</div>
        <div
          class="myorder box_top"
          :class="xuanzhong === 3 ? 'xuanzhong' : ''"
        >
          <router-link :to="{ path: '../order' }">我的订单</router-link>
        </div>
        <div
          class="Service_evaluation box_top"
          :class="xuanzhong === 4 ? 'xuanzhong' : ''"
        >
          <router-link :to="{ path: '../serviceEvalution' }"
            >服务评价</router-link
          >
        </div>
      </div>
      <i></i>
      <!-- 财务中心 -->
      <div
        class="sidebar_financial"
        v-if="user_rule == 7 || user_rule == 1"
      >
        <div class="financial sets">财务中心</div>
        <div class="box_top" :class="xuanzhong === 5 ? 'xuanzhong' : ''">
          <router-link :to="{ path: '../financial' }">统计核算</router-link>
        </div>
        <div
          class="haishi_account box_top"
          :class="xuanzhong === 6 ? 'xuanzhong' : ''"
        >
          <router-link :to="{ path: '../haishiaccount' }">海师统计</router-link>
        </div>
        <div
          class="kehu_account box_top"
          :class="xuanzhong === 7 ? 'xuanzhong' : ''"
        >
          <router-link :to="{ path: '../customerAccount' }"
            >客户统计</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavLeft",
  data() {
    return {
      user_rule: 2,
    };
  },
  props: {
    xuanzhong: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    initFun() {
      this.user_rule = this.$cookie.get("user_rule");
    },
  },
  mounted() {
    this.initFun();
  },
};
</script>

<style lang="scss" scoped>
#NavLeft {
  div .xuanzhong {
    color: #d99d66 !important;
    border-left: 4px solid #d99d66 !important ;
  }

  // 侧边栏
  .sidebar {
    width: 200px;
    // height: 664px;
    background: #ffffff;
    border-radius: 8px;
    //  账户设置
    .sidebar_account {
      // margin-top: 16px;
      padding: 60px 93px 0px 0;
      div {
        text-align: right;
        height: 21px;
        line-height: 21px;
      }
      .accounts {
        font-size: 16px;
        font-weight: 500;
        color: #999999;
      }
      .persons {
        font-size: 16px;
        font-weight: bold;
        color: #999999;
        line-height: 24px;
      }
      .touxiang {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
      }
    }
    // 线条
    i {
      width: 120px;
      height: 1px;
      background: #ededed;
      display: inline-block;
      margin: 40px;
    }
    // 订单中心
    .sidebar_order {
      padding: 0px 93px 0px 0;
      div {
        height: 21px;
        line-height: 21px;
        text-align: right;
      }
    }

    // 标题共用
    .sets {
      font-size: 16px;
      font-weight: 500;
      color: #999999;
    }
    // 财务中心
    .sidebar_financial {
      padding: 0px 93px 0px 0;
      text-align: right;
      padding-bottom: 72px;
    }
  }
  // 字体公用样式
  .box_top {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }
}
// 下划线样式去除
a {
  text-decoration: none;
  color: #333333;
}

.router-link-active {
  // text-decoration: none;
  color: #d99d66;
  font-weight: bold;
}
</style>
