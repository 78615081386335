<template>
  <div class="breads">
    <!-- 顶部面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
     <el-breadcrumb-item
          :to="item.path"
          v-for="(item, index) in nav_title"
          :key="index"
          >{{ item.name }}</el-breadcrumb-item
        >
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  name: "breadCrumb",
  data() {
    return {
      curPath:''
    };
  },
  props: {
    type: Object,
    nav_title: {
      default: [{ name: "账户设置" }, { name: "个人信息" }],
    },
    order: {},
  },
  mounted () {
    this.curPath=this.$route.path
  },
};
</script>

<style lang="scss" scoped>
.breads {
  height: 56px;
  display: flex;
  align-items: center;
}
</style>
